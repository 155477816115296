import '../../styles/App.scss';
import './WelcomeSection.scss';
import { Card } from "react-bootstrap";
import { Subscription } from '../../models';
import SubscriptionBadge from '../../components/SubscriptionBadge/SubscriptionBadge';

import logo from '../../assets/images/mynu-white-logo.png';

type WelcomeSectionProps = {
  userName?: string | null;
  userGender?: string | null;
  subscription?: Subscription | null;
}

function WelcomeSection({ userName, userGender, subscription }: WelcomeSectionProps) {
  return (
    <div className="mb-4 welcome-section">
      <Card className="">
        <Card.Header >
          <div className='d-flex flex-column'>
            <div className='mt-3 d-flex justify-content-center'>
              <img src={logo} alt='mynu-logo' />
            </div>
            <SubscriptionBadge subscription={subscription} />
            <div className="mt-3 d-block d-md-none">
              Tu camino hacia una vida más saludable comienza aquí.
              Personalizamos planes de nutrición para ayudarte a alcanzar tus objetivos de bienestar.
            </div>
            <div className="mt-3 d-none d-md-block">
              <div>
                Tu camino hacia una vida más saludable comienza aquí.
              </div>
              <div>
                Personalizamos planes de nutrición para ayudarte a alcanzar tus objetivos de bienestar.
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className='mx-2 d-flex justify-conent-center'>
            <h1 className="home-title">{`¡${userName}, ${!userGender ? 'bienvenido/bienvenida' : userGender === 'Femenino' ? 'bienvenida' : 'bienvenido'} a Mynu!`}</h1>
          </div>
        </Card.Body>
      </Card>
    </div >
  )
}

export default WelcomeSection;
