export const diet_patterns: any = {
  pescatarian: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Alimentos ricos en vitamina D\ud83d\udc1f\ud83e\udd5b\ud83c\udf5e",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Carnes blancas, magras, pescados y mariscos \ud83c\udf57\ud83d\udc1f\ud83e\udd9e",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Huevos \ud83c\udf73",
    "Lácteos \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos\ud83e\udd5b\ud83e\uddc0",
    "Lácteos sin lactosa\ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pan de masa madre \ud83c\udf5e",
    "Pescados grasos y mariscos \ud83d\udc20\ud83e\udd90\ud83e\uddaa",
    "Pescados y mariscos \ud83d\udc1f\ud83d\udc20\ud83e\udd90",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Postres de leche \ud83c\udf6e",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Camarón \ud83e\udd90",
    "Cebada y derivados \ud83c\udf7a",
    "Centeno y derivados \ud83e\uded3",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Crustáceos \ud83e\udd90\ud83e\udd9e\ud83e\udd80",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Postres de leche \ud83c\udf6e",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
  ovo_lacto_vegetarian: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Alimentos ricos en vitamina D\ud83d\udc1f\ud83e\udd5b\ud83c\udf5e",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Huevos \ud83c\udf73",
    "Lácteos \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos\ud83e\udd5b\ud83e\uddc0",
    "Lácteos sin lactosa\ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pan de masa madre \ud83c\udf5e",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Postres de leche \ud83c\udf6e",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cebada y derivados \ud83c\udf7a",
    "Centeno y derivados \ud83e\uded3",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Postres de leche \ud83c\udf6e",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
  ovo_vegetarian: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Alimentos ricos en vitamina D\ud83d\udc1f\ud83e\udd5b\ud83c\udf5e",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Huevos \ud83c\udf73",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pan de masa madre \ud83c\udf5e",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Jugos de fruta \ud83e\uddc3",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cebada y derivados \ud83c\udf7a",
    "Centeno y derivados \ud83e\uded3",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
  lacto_vegetarian: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Alimentos ricos en vitamina D\ud83d\udc1f\ud83e\udd5b\ud83c\udf5e",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Lácteos \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos\ud83e\udd5b\ud83e\uddc0",
    "Lácteos sin lactosa\ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pan de masa madre \ud83c\udf5e",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Postres de leche \ud83c\udf6e",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cebada y derivados \ud83c\udf7a",
    "Centeno y derivados \ud83e\uded3",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Postres de leche \ud83c\udf6e",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
  vegan: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pan de masa madre \ud83c\udf5e",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Jugos de fruta \ud83e\uddc3",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Cebada y derivados \ud83c\udf7a",
    "Centeno y derivados \ud83e\uded3",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
  no_gluten: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Alimentos ricos en vitamina D\ud83d\udc1f\ud83e\udd5b\ud83c\udf5e",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Carnes blancas, magras, pescados y mariscos \ud83c\udf57\ud83d\udc1f\ud83e\udd9e",
    "Carnes rojas bajas en grasa \ud83e\udd69\ud83c\udf56",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Huevos \ud83c\udf73",
    "Lácteos \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos\ud83e\udd5b\ud83e\uddc0",
    "Lácteos sin lactosa\ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pescados grasos y mariscos \ud83d\udc20\ud83e\udd90\ud83e\uddaa",
    "Pescados y mariscos \ud83d\udc1f\ud83d\udc20\ud83e\udd90",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Carnes rojas \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Carnes rojas altas en grasa \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Embutidos \ud83c\udf2d\ud83e\udd53\ud83c\udf56",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Postres de leche \ud83c\udf6e",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Camarón \ud83e\udd90",
    "Carnes rojas \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Carnes rojas altas en grasa \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Crustáceos \ud83e\udd90\ud83e\udd9e\ud83e\udd80",
    "Embutidos \ud83c\udf2d\ud83e\udd53\ud83c\udf56",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Postres de leche \ud83c\udf6e",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
  fasting: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Alimentos ricos en vitamina D\ud83d\udc1f\ud83e\udd5b\ud83c\udf5e",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Carnes blancas, magras, pescados y mariscos \ud83c\udf57\ud83d\udc1f\ud83e\udd9e",
    "Carnes rojas bajas en grasa \ud83e\udd69\ud83c\udf56",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Huevos \ud83c\udf73",
    "Lácteos \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos\ud83e\udd5b\ud83e\uddc0",
    "Lácteos sin lactosa\ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pan de masa madre \ud83c\udf5e",
    "Pescados grasos y mariscos \ud83d\udc20\ud83e\udd90\ud83e\uddaa",
    "Pescados y mariscos \ud83d\udc1f\ud83d\udc20\ud83e\udd90",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Carnes rojas \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Carnes rojas altas en grasa \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Embutidos \ud83c\udf2d\ud83e\udd53\ud83c\udf56",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Postres de leche \ud83c\udf6e",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Camarón \ud83e\udd90",
    "Carnes rojas \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Carnes rojas altas en grasa \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Cebada y derivados \ud83c\udf7a",
    "Centeno y derivados \ud83e\uded3",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Crustáceos \ud83e\udd90\ud83e\udd9e\ud83e\udd80",
    "Embutidos \ud83c\udf2d\ud83e\udd53\ud83c\udf56",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Postres de leche \ud83c\udf6e",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
  low_carb: [
    "Aceite de oliva o canola \ud83e\uded2",
    "Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c",
    "Alimentos altos en kaempferol\ud83e\udd55\ud83e\udd6c\u2615",
    "Alimentos con quercetina\ud83e\uddc5\ud83e\udd6c\ud83c\udf52",
    "Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda",
    "Alimentos fortificados en vitamina D \ud83e\udd5b\ud83e\udd56\ud83d\udc1f",
    "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59",
    "Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b",
    "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90",
    "Alimentos ricos en vitamina D\ud83d\udc1f\ud83e\udd5b\ud83c\udf5e",
    "Canela\ud83e\udeb5",
    "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3",
    "Carnes blancas, magras, pescados y mariscos \ud83c\udf57\ud83d\udc1f\ud83e\udd9e",
    "Carnes rojas bajas en grasa \ud83e\udd69\ud83c\udf56",
    "Chocolate alto en cacao \ud83c\udf6b",
    "Cúrcuma y pimienta \ud83e\uddc2\ud83e\udeda",
    "Frutas ácidas y semiácidas \ud83c\udf4f\ud83e\udd5d\ud83c\udf4d",
    "Frutas altas en grasa \ud83e\udd5c\ud83e\udd51\ud83e\uded2",
    "Frutas cítricas \ud83c\udf4a\ud83e\uded1\ud83e\udd5d",
    "Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52",
    "Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2",
    "Harina de trigo",
    "Pan\ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a",
    "Hierbas y Té\ud83c\udf75\ud83c\udf3f",
    "Hortalizas de hoja verde \ud83e\udd6c\ud83c\udf43\ud83e\udd57",
    "Hortalizas ricas en almidón \ud83e\udd54\ud83c\udf3d\ud83e\udedb",
    "Hortalizas rojas-naranjas-moradas \ud83e\udd55\ud83c\udf45\ud83e\uded1",
    "Huevos \ud83c\udf73",
    "Lácteos \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con bajo contenido de lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa con fitoesteroles \ud83e\udd5b",
    "Lácteos sin lactosa \ud83e\udd5b\ud83e\uddc0",
    "Lácteos\ud83e\udd5b\ud83e\uddc0",
    "Lácteos sin lactosa\ud83e\udd5b\ud83e\uddc0",
    "Leche de almendras\ud83e\udd5b",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Pan de masa madre \ud83c\udf5e",
    "Pescados grasos y mariscos \ud83d\udc20\ud83e\udd90\ud83e\uddaa",
    "Pescados y mariscos \ud83d\udc1f\ud83d\udc20\ud83e\udd90",
    "Proteínas vegetales\ud83e\uded8\ud83e\udedb",
    "Ralladura de cítricos\ud83c\udf4b\ud83c\udf4a",
    "Semillas \ud83c\udf3b",
    "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e",
    "Vinagre\ud83e\uded9",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Carnes rojas \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Carnes rojas altas en grasa \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Embutidos \ud83c\udf2d\ud83e\udd53\ud83c\udf56",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Postres de leche \ud83c\udf6e",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
    "Aceite de palma y aceite de coco \ud83e\udd65\ud83c\udf34",
    "Aceites de maravilla, soya, maiz \ud83c\udf3b",
    "Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a",
    "Avena no certificada sin gluten \ud83e\udd63",
    "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d",
    "Camarón \ud83e\udd90",
    "Carnes rojas \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Carnes rojas altas en grasa \ud83e\udd53\ud83e\udd69\ud83c\udf56",
    "Cebada y derivados \ud83c\udf7a",
    "Centeno y derivados \ud83e\uded3",
    "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50",
    "Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2",
    "Crustáceos \ud83e\udd90\ud83e\udd9e\ud83e\udd80",
    "Embutidos \ud83c\udf2d\ud83e\udd53\ud83c\udf56",
    "Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Jugos de fruta \ud83e\uddc3",
    "Lácteos con lactosa \ud83e\udd5b\ud83e\uddc0",
    "Legumbres \ud83e\uded8\ud83e\udedb\ud83c\udf72",
    "Maní \ud83e\udd5c",
    "Postres de leche \ud83c\udf6e",
    "Productos a granel \ud83e\udd5c\ud83c\udf30\ud83e\uded8",
    "Productos de trigo procesados \ud83c\udf5e\ud83c\udf70\ud83e\udd50",
    "Quesos untables, blancos y frescos\ud83e\uddc0",
    "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e",
    "Ultraprocesados \ud83c\udf5f\ud83e\udd64\ud83c\udf6b",
    "Ultraprocesados altos en azúcar\ud83e\udd64\ud83c\udf6b\ud83c\udf66",
    "Ultraprocesados altos en calorías\ud83c\udf5f\ud83c\udf6b\ud83e\uddc1",
    "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d",
  ],
};
