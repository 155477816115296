export const calculateBackoffTime = (attempt: number): number => {
  return 2000;
};

/**
 * Checks is value is (undefined or null) and empty
 * @param value any: the value to be tested 
 * @param kind string: the type to check if empty
 * @returns 
 */
export const isEmptyOrUndefined = (value: any, kind: string): boolean => {
  if (value === undefined || value === null) return true;

  switch (kind) {
    case 'str':
      return (typeof (value) === 'string' && value.trim() === '');
    case 'arr':
      return (Array.isArray(value) && value.length === 0);
    case 'obj':
      return (typeof (value) === 'object' && Object.keys(value).length === 0);
    default:
      return false;
  }
}

export const parseBool = (value: any): boolean => {
  if (value === 'true' || value === 1 || value === true) return true;
  else return false;

}

/**
 * Removes all undefined and null values from an array
 */
export const rmUndefinedOrNulls = (value: any[]) => {
  return value.filter((v) => v !== undefined && v !== null);
}

/**
 * Removes duplicate objects/values from an array
 */
export const rmDuplicates = (arr: any, kindToRemove: string, key?: string) => {
  switch (kindToRemove) {
    case 'obj':
      if (key && key !== '') {
        return arr.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t[key] === v[key])) === i);
      } else {
        throw new Error('Key is required to remove duplicates from an object');
      }
    case 'arr':
      return arr.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    default:
      return arr;
  }
}

/**
 * formats a number to chile currency format
 * @param {number} value to apply format
 * @returns string with desired format
 */
export const formatCLP = (value: number): string => {
  return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
}

/**
 * Checks if word2 includes partially word1 based of a threshold
 * @param {string} word1
 * @param {string} word2
 * @param {number} threshold
 * @returns true or false if strings are similar 
 */
export const compareStrings = (word1: string, word2: string, threshold: number): boolean => {
  if (!word1 || !word2) throw new Error('No strings to compare');

  const regex = /[\s\p{Emoji_Presentation}]/gu;
  const w1 = Array.from(new Set(word1.toLowerCase().replaceAll(regex, "")));
  const w2 = Array.from(new Set(word2.toLowerCase().replaceAll(regex, "")));
  const intersection = w1.sort().map(c => w2.sort().includes(c)  ? c : '').join("");

  if (intersection && intersection?.length > 0) {
    const maxLength = Math.max(w1.length, w2.length);
    const similarity = intersection.length / maxLength;
    return similarity > threshold;
  }
  return false;
}

/**
 * 
 * @param s string to be normalized
 * @returns normalized string with NFD type and no emojis 
 */
export const normalizeString = (s: string): string => {
  return s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}


export const truncateTo = (n: number, d: number) => {
  return Math.trunc(n * d) / d;
}