import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Collapse from 'react-bootstrap/Collapse';

import { ArrowDownShort, ArrowUpShort, Check2Circle, CheckAll, EyeFill, EyeSlashFill, Plus, QuestionCircleFill, StarFill, X } from "react-bootstrap-icons";

import { fesGetDailyReport } from "../../../../services/diet";

import { fesFoodVarietyReportType, fesNutrientReportType, fesReportType } from "../../../../models/fes-model";

import {
  adaptationLevels, fiberAnalysis, macroNutrients, microNutrientsSources,
  nutrientTypes, objectives, objectivesMap, omega3Analysis, starsEvaluation
} from "./objectivesMap";
import { DAY } from "../../../../utils/constants";

import { useCurrentUser } from "../../../../components/AuthContext";
import { useStateStore } from "../../../../store/store";
import { loadUserInfo } from "../../../../store/loader";

import MainLayout from "../../../../components/MainLayout/MainLayout";
import Spinner from "../../../../components/Spinner/Spinner";
import { NotFoundPage } from "../../../../router/404Page";
import { RenderRecipeScore } from "./FesCardComponent";
import { UTC_OFFSET } from "./FesComponent";
import BackButton from "../../../../components/BackButton/BackButtton";
import { MacronutrientsChart } from "./components/MacroChart/MacroNutrientsChart";
import ToggleButtonComponent from "../../../../components/ToggleButton/ToggleButton";
import { adaptationLevelFromBoundsAndValue } from "../../../../utils/fes";

export default function FesReportsPage() {
  const currentUser = useCurrentUser();
  const store = useStateStore();
  const [loading, setLoading] = useState<boolean>(false);

  // params from navigation
  const { state } = useLocation();

  const [fesReport, setFesReport] = useState<fesReportType | null>(null);
  const [goalId, setGoalId] = useState<objectives | null>(null);
  const [day, setDay] = useState<DAY | null>(null);

  useEffect(() => {
    if (currentUser) {
      loadUserInfo(store, currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    setLoading(true);
    if (state && state.hasOwnProperty('goalId') && state.hasOwnProperty('date')) {
      fesGetDailyReport(state.date)
        .then((response) => {
          setGoalId(state.goalId);
          setFesReport(response);
          setDay(state.day);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state]);
  return (
    !loading && fesReport &&
      fesReport.nutrients && fesReport.nutrients.length > 0
      && goalId && day ?
      <MainLayout>
        <Stack direction="vertical" gap={2} className="my-4 pt-3 px-0 reports">
          <div className="reports-header" >
            <div className="d-flex flex-row gap-2 align-items-center">
              <BackButton text={""} />
              <h4> Mi Reporte Diario </h4>
            </div>
            <div className="px-2 header-title-right">
              <div className="m-1" style={{ fontSize: '1.4em' }}>{day}</div>
              <div className="m-1"> {new Date(state.date + UTC_OFFSET).toLocaleDateString('es-CL',)} </div>
            </div>
          </div>

          <div className="reports-main-container px-2">
            <Stack direction="vertical" gap={2} className="item1">
              <DailyConsumptionScoreComponent
                score={fesReport.overallScore.toFixed(1)}
                lackingSubcategories={fesReport.improvementSections}
              />
              <MacronutrientsComponent
                goal={goalId}
                protein={fesReport.nutrients.find(n => n.name === 'protein') ?? null}
                carbs={fesReport.nutrients.find(n => n.name === 'carbs') ?? null}
                fat={fesReport.nutrients.find(n => n.name === 'fat') ?? null}
              />
            </Stack>
            <Stack direction="vertical" gap={2} className="item2">
              <CaloriesComponent
                goal={goalId}
                calories={fesReport.nutrients.find(n => n.name === 'calories')}
              />
              <MicroNutrientsComponent
                nutrients={fesReport.nutrients}
              />
            </Stack>
            <Stack direction="vertical" gap={2} className="item3">
              <IngestedFoodQualityComponent
                score={fesReport.recipeScoresSummary.average ?? 0}
              />
              <CriticalNutrients
                fiber={fesReport.nutrients.find(n => n.name === 'fiber')}
                omega3={fesReport.foodVariety.find(f => f.label === 'omega3')}
              />
            </Stack>
          </div>

        </Stack>
      </MainLayout >
      : loading ?
        <MainLayout>
          <div className="d-flex flex-column align-items-center">
            <Spinner size="large" rotate />
            <h1>
              Cargando
            </h1>
          </div>
        </MainLayout>
        :
        <NotFoundPage />
  );
}


const DailyConsumptionScoreComponent = ({ score, lackingSubcategories }: { score: string, lackingSubcategories: string[] }) => {
  let scoreNumber = parseFloat(score);

  const r = 40;
  const border = 15;
  const c = useMemo(() => 2 * Math.PI * r, []);
  const sLength = useMemo(() => scoreNumber / 10 * c, [scoreNumber, c])
  const rLength = useMemo(() => c - sLength, [c, sLength])

  return (
    <Card className="mynu-shadow">
      <Card.Header>
        Puntuación total del día
      </Card.Header>
      <Card.Body>
        <div className="daily-consumption-score">
          <div className="donut-container">
            <div className="donut">
              <svg viewBox="0 0 100 100">
                {/* magic */}
                <filter id="inset-shadow" x="-50%" y="-50%" width="200%" height="200%">
                  <feComponentTransfer in="SourceAlpha">
                    <feFuncA type="table" tableValues="1 0" />
                  </feComponentTransfer>
                  <feGaussianBlur stdDeviation="4" />
                  <feOffset dx="0" dy="5" result="offsetblur" />
                  <feFlood floodColor="rgb(0, 0, 0, 0.3)" result="color" />
                  <feComposite in2="offsetblur" operator="in" />
                  <feComposite in2="SourceAlpha" operator="in" />
                  <feMerge>
                    <feMergeNode in="SourceGraphic" />
                    <feMergeNode />
                  </feMerge>
                </filter>

                <circle
                  id={'donut-inner'} cx="50%" cy="50%" r={r}
                  strokeWidth={border}
                  fill="transparent"
                  stroke="#fff" strokeDasharray="360, 100"
                  filter="url(#inset-shadow)"
                />

                <circle
                  id={'donut-percentage'} cx="50%" cy="50%" r={r}
                  strokeWidth={border}
                  fill="transparent" strokeDasharray={`${sLength}, ${rLength}`}
                />
              </svg>
            </div>
            <div className="score-text">
              <h2> {(scoreNumber).toLocaleString('es-CL')} </h2>
              <h5 style={{ margin: '-10% 0 0' }}> /10 </h5>
            </div>
          </div>

          <div className="recomended-improvement">
            <AdaptationHeaderComponent
              level={lackingSubcategories && lackingSubcategories.length > 0 ?
                adaptationLevels.L : adaptationLevels.M}
              title={'Busca mejorar en'}
              showIcon
            />
            <div className="improve">
              {lackingSubcategories && lackingSubcategories.length > 0 ?
                lackingSubcategories.map((sc, i) =>
                  <div key={`lacks-${sc}-${i}`}>
                    <div>
                      {sc}
                    </div>
                  </div>
                ) : null}
            </div>
          </div>
        </div>

      </Card.Body>
    </Card>
  )
};

const MacronutrientsComponent = (
  props: {
    goal: objectives,
    protein: fesNutrientReportType | null,
    carbs: fesNutrientReportType | null,
    fat: fesNutrientReportType | null,
  }) => {

  const {
    goal,
    protein,
    carbs,
    fat,
  } = props;

  const macros: macroNutrients[] = [
    macroNutrients.PROTEINS,
    macroNutrients.CARBS,
    macroNutrients.FAT
  ];
  const [selectedMacro, setSelectedMacro] = useState<fesNutrientReportType | null>(protein);

  const handleClick = (macro: macroNutrients) => {
    setSelectedMacro(
      macro === macroNutrients.PROTEINS ? protein
        : macro === macroNutrients.CARBS ? carbs
          : fat
    )
  }
  
  const adaptation = useMemo(() => {
    if (!selectedMacro) return null;
    
    const actual = selectedMacro.value;
    const lb = selectedMacro.dailyLb;
    const ub = selectedMacro.dailyUb;
    
    return adaptationLevelFromBoundsAndValue(actual, lb, ub);
  }, [selectedMacro]);
  
  return (
    protein && carbs && fat ?
      <Card className="mynu-shadow card-bottom">

        <Card.Header> Macronutrientes </Card.Header>

        <Card.Body className="macro-body">
          {/* macro selector */}
          <div className="buttons-container">
            {macros.map((macro: macroNutrients, i) => {
              let name = translateToEs(macro);
              return (name ?
                <div
                  key={`macro-button-${macro}`}
                  className={`mb-2 ${selectedMacro?.name === macro ? 'selected' : 'macro-button'}`}
                  onClick={() => handleClick(macro)}
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </div>
                : null
              )
            })
            }
          </div>

          {selectedMacro && adaptation &&
            objectivesMap[goal][nutrientTypes.MACROS][selectedMacro.name as macroNutrients][adaptation] ?
            <div className="h-100 macro-body-content">
              {/* charts */}
              <div className="mb-2">
                {selectedMacro ?
                  <MacronutrientsChart
                    name={selectedMacro.name}
                    value={selectedMacro.value}
                    dailyLb={selectedMacro.dailyLb}
                    dailyUb={selectedMacro.dailyUb}
                    unit={selectedMacro.unit}
                    level={adaptation}
                  />
                  : null
                }
              </div>
              {/* Text box */}
              <div className={`${adaptation}`}>
                <div className="font-avenir-bold">
                  {objectivesMap[goal].macros[selectedMacro.name as macroNutrients][adaptation].title}
                </div>
                <div className="h-50 d-flex color-text">
                  {objectivesMap[goal].macros[selectedMacro.name as macroNutrients][adaptation].description}
                </div>
              </div>
            </div>
            : null
          }
        </Card.Body >
      </Card >
      : null
  )
}

const CaloriesComponent = (
  props: {
    goal?: objectives;
    calories?: fesNutrientReportType;
  }
) => {
  const {
    goal,
    calories
  } = props;

  const getCalories = useMemo(() => {
    if (calories && calories.dailyLb && calories.dailyUb) {
      return (calories.dailyLb + calories.dailyUb) / 2;
    }
    return null;
  }, [calories])

  const dailyConsumptionInterval = useMemo(() => calculateDailyConsumptionLevel(calories?.value, getCalories), [calories, getCalories]);

  const [isVisible, setIsVisible] = useState(false);

  return (
    goal && calories && dailyConsumptionInterval && getCalories &&
      objectivesMap[goal].calories[dailyConsumptionInterval] ?
      <Card className="mynu-shadow">
        <Card.Header>
          Calorías
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-column align-items-center">
            <AdaptationHeaderComponent
              level={dailyConsumptionInterval}
              title={objectivesMap[goal].calories[dailyConsumptionInterval].title}
              inverseIcon
              showIcon
            />
            <div className="mb-4">
              <ToggleButtonComponent
                value={isVisible}
                changeValue={setIsVisible}
                on={<EyeFill className="mynu-stars" size={'1rem'} />}
                off={<EyeSlashFill className="mynu-stars" size={'1rem'} />}
              />
            </div>
            <div className="w-100">
              <Collapse in={isVisible}>
                <div>
                  <MacronutrientsChart
                    name={calories.name}
                    value={calories.value}
                    unit={calories.unit}
                    dailyLb={calories.dailyLb}
                    dailyUb={calories.dailyUb}
                    level={dailyConsumptionInterval}
                  />
                </div>
              </Collapse>
            </div>
            {objectivesMap[goal].calories[dailyConsumptionInterval].description !== null ?
              <span className="m-2">
                {objectivesMap[goal].calories[dailyConsumptionInterval].description}
              </span>
              : null}
          </div>
        </Card.Body>
      </Card>
      : null
  )
}

const IngestedFoodQualityComponent = ({ score }: { score: number }) => {
  const adaptation = useMemo(() => {
    if (score <= -0.6)
      return adaptationLevels.VL;
    else if (score < 0)
      return adaptationLevels.L;
    else if (score < 0.8)
      return adaptationLevels.M;
    else
      return adaptationLevels.H;
  }, [score]);

  return (adaptation && score ?
    <Card className="mynu-shadow">
      <Card.Header>
        Calidad de alimentación
      </Card.Header>
      <Card.Body>
        <Container className="mb-4">
          <Row className="justify-content-center">
            <RenderRecipeScore score={score} size={'40px'} />
          </Row>
        </Container>
        <div className="d-flex flex-column align-items-center">
          <AdaptationHeaderComponent
            level={adaptation}
            title={starsEvaluation[adaptation].title}
            showIcon={adaptation !== adaptationLevels.H}
          />
          <div>
            {starsEvaluation[adaptation].recommendation}
          </div>
        </div>
      </Card.Body>
    </Card>
    : null)
}


const MicroNutrientsComponent = ({ nutrients }: { nutrients: fesNutrientReportType[] }) => {

  let vitaminMinteralsNutrients = microNutrientsSources.map((microNutrient) => microNutrient.name);

  let data = nutrients.filter((nutrient) => vitaminMinteralsNutrients.includes(nutrient.name));

  let consumeMore = data.filter((n) => {
    if (n.name !== 'sodium' && ((n.value / n.dailyLb) < 1)) {
      return true;
    }
    return false;
  }).map(n => n.name);

  return (
    data.length > 0 ?
      <Card className="card-bottom micro-card mynu-shadow">
        <Card.Header> Vitaminas y Minerales </Card.Header>

        <Card.Body>
          <div className="micro-chart-header">
            <div><Badge pill>Mi consumo</Badge></div>
            <div>
              <Badge pill className="mx-1 py-0 px-2">
                <Plus size={25} />
              </Badge>
              <div>Mayor cantidad de vitaminas</div>
            </div>
          </div>
          <Carousel data-bs-theme="dark">
            {Array.from({ length: Math.ceil(data.length / 3) }, (_, i) => {
              return (
                <Carousel.Item key={`carousel-micro-nutrients-${i}`} className="p-0">
                  <div className='w-100 d-flex justify-content-center' >
                    {data.slice(i * 3, i * 3 + 3).map((vitNtr, j) => {
                      let micro = microNutrientsSources.find(m => m.name === vitNtr.name);
                      return (
                        micro && micro?.label.length > 0 ?
                          <div style={{ width: '25%' }} key={vitNtr.name + '-' + j}>
                            <div className={'font-avenir-bold'} style={{ textAlign: 'center', height: '40px' }}>
                              {micro.label}
                            </div>
                            <div key={`chart-macro-${micro.name}`} style={{ height: '200px' }} >
                              <CustomSlider
                                name={vitNtr.name}
                                value={vitNtr.value}
                                lb={vitNtr.dailyLb}
                                ub={vitNtr.dailyUb}
                                unit={vitNtr.unit}
                                details={micro.sources.join(', ')}
                              />
                            </div>
                          </div>
                          : null)
                    })}
                  </div>
                </Carousel.Item>
              )
            })
            }
          </Carousel>
          <div className="micro-text-recommendation">
            <b className="font-avenir-bold">
              Aumenta el consumo de
            </b>: {consumeMore.map(n => microNutrientsSources.find(m => n === m.name)?.label ?? null).join(', ')}
          </div>
        </Card.Body>
      </Card>
      : null
  )
}

export const CustomSlider = (
  props: {
    value: number,
    lb: number,
    ub: number,
    unit?: string,
    name?: string,
    details?: string
  }
) => {
  const { value, lb, ub, name, details } = props;

  const barColor = useMemo(() => {
    switch (name) {
      case 'sodium':
        return value < ub ? '#33B591' : '#FE5133';
      default:
        return lb < value ? '#33B591' : '#FE5133';
    }
  }, [value, ub, lb, name]);

  const computedPercentage = useMemo(() => {
    const middleLine = name === 'sodium' ? ub : lb;
    const ratio = value / middleLine;
    return Math.max(0, Math.min(100, ratio >= 2.5 ? 100 : Math.trunc(ratio * 50)));

  }, [value, name, lb, ub])

  return (
    <div id={`custom-slider-micros-${name}`} className="custom-range m-auto mt-3">
      <div className="holder mynu-inner-shadow ">
        <hr />
        <div className="font-avenir-bold"> </div>
        <div className="font-avenir-bold">+</div>
        <div className="slider"
          style={{ backgroundColor: barColor, height: `${computedPercentage}%` }}
        />
      </div>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover id={`custom-slider-micros-tooltip-${name}`} >
            <Popover.Body>
              {details}
            </Popover.Body>
          </Popover>
        }
      >
        <QuestionCircleFill size={20} className="mynu-stars" />
      </OverlayTrigger>
    </div>
  )
}

export const CriticalNutrients = ({ fiber, omega3 }: { fiber?: fesNutrientReportType, omega3?: fesFoodVarietyReportType }) => {

  const adaptationLevel = useMemo(() => {
    if (fiber && fiber.dailyLb) {
      return fiber.value < fiber.dailyLb ? adaptationLevels.VL : adaptationLevels.M;
    }
    return null;
  }, [fiber]);

  const omega3Obj = useMemo(() => {
    if (omega3 && omega3.count) {
      return 1 <= omega3.count ? adaptationLevels.M : null;
    }
    return null;
  }, [omega3]);
  return (
    fiber && adaptationLevel ?
      <Card className="mynu-shadow">
        <Card.Header>
          Nutrientes críticos
        </Card.Header>
        <Card.Body>
          <div className="flex-container flex-column">
            <BadgeCheckComponent
              ok={fiber.value >= fiber.dailyLb}
              title={'Fibra'}
              description={fiberAnalysis[adaptationLevel].title}
              recommended={fiberAnalysis[adaptationLevel].recommendation}
              icons={fiberAnalysis[adaptationLevel].icons}
            />

            {omega3Obj ?
              <div className="font-avenir-bold my-3 p-4 w-100 d-flex flex-column align-items-center"
                style={{ borderRadius: '18px', border: '0.5px solid lightgray', backgroundColor: '#F7F3FF' }}
              >
                <Badge pill bg="primary" className="mb-3" style={{ fontSize: '1em' }}>
                  <Stack direction="horizontal" gap={2}>
                    <div>Bonus</div>
                    <StarFill size={20} className="mb-1" />
                  </Stack>
                </Badge>

                <BadgeCheckComponent
                  title={omega3Analysis[adaptationLevels.M].title}
                  ok={true}
                  icons={null}
                />
              </div>
              : null
            }
          </div>
        </Card.Body>
      </Card>
      : null
  )
}

const BadgeCheckComponent = (props: {
  title: string,
  ok: boolean,
  description?: string | null,
  recommended?: string[] | null,
  icons: string[] | null
}) => {
  const { title, ok, description, recommended, icons } = props;
  return (
    title ?
      <div className="flex-container flex-column">
        <div
          style={{ borderRadius: '18px', border: '0.5px solid lightgray', backgroundColor: '#FAFAFA' }}
          className="font-avenir-bold py-1 px-3 mb-3 d-flex flex-row align-items-center"
        >
          <div className="mynu-stars" style={{ 'borderRadius': '100%' }}>
            {ok ?
              <Check2Circle size={24} className="mx-1" />
              : <X size={24} />
            }
          </div>
          <div > {title} </div>
        </div>
        {description ?
          <>{description}</>
          : null
        }
        {!ok && recommended && icons ?
          <Stack direction="horizontal" gap={2} className="my-3">
            {recommended.map((r, i) => (
              <Stack direction='vertical' gap={2} key={`recommended-foods-badges${i}`} className="d-flex flex-column align-items-center">
                <div className={`fiber-img-${icons[i]}`} />
                <span>{r}</span>
              </Stack>
            ))}
          </Stack>
          : null
        }
      </div>
      : null
  )
}

export const translateToEs = (unit: string | undefined) => {
  switch (unit) {
    case 'calories':
      return 'Calorías';
    case 'protein':
      return 'Proteínas';
    case 'carbs':
      return 'Carbohidratos';
    case 'fat':
      return 'Grasas';
    case 'grams':
      return 'g';
    case 'calorías':
      return 'cal'
    default:
      return unit;
  }
}


const calculateDailyConsumptionLevel = (value: number | undefined, avg: number | null) => {
  if (value !== undefined && avg !== null) {
    const ratio = value / avg;
    if (ratio <= 0.7) {
      return adaptationLevels.VL;
    } else if (ratio <= 0.9) {
      return adaptationLevels.L;
    } else if (ratio <= 1.1) {
      return adaptationLevels.M;
    } else if (ratio <= 1.5) {
      return adaptationLevels.H;
    } else {
      return adaptationLevels.VH;
    }
  }
  return null;
}


const AdaptationHeaderComponent = ({ level, title, inverseIcon, showIcon }:
  { level: adaptationLevels, title: string, inverseIcon?: boolean, showIcon: boolean }) => {
  return (
    <div className="adaptation-header-component">
      <Stack direction="horizontal" gap={2} className="div-stack">
        {showIcon ?
          <div className="level">
            {level && [adaptationLevels.VL, adaptationLevels.L].includes(level)
              ? <> {!inverseIcon ? <ArrowUpShort /> : <ArrowDownShort />} </>
              : [adaptationLevels.VH, adaptationLevels.H].includes(level)
                ? <> {!inverseIcon ? <ArrowDownShort /> : <ArrowUpShort />} </>
                : <CheckAll />
            }
          </div>
          : null}
        <div> {title} </div>
      </Stack>
    </div>
  )
}