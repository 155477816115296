import { useNavigate } from 'react-router';
import { Button, Stack } from 'react-bootstrap';
import { ArrowLeftShort } from 'react-bootstrap-icons';
import './BackButton.css';

type BackButtonProps = {
  text: string;
  color?: string;
}

function BackButton({ text, color }: BackButtonProps) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  return (

    <Button onClick={goBack} variant="link" className="back-button">
      <Stack direction='horizontal' gap={2} style={{ color: color ?? undefined}}>
        <ArrowLeftShort size={26}/>
        {text}
      </Stack>
    </Button>
  )
}

export default BackButton;