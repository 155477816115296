import { axiosInstance } from "../../api/http";
import { MessagesResponse } from "../../models";


export async function sendMessageToChat(userToken: string, question: string): Promise<string> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    const response = await axiosInstance.post(
      '/chat-messages',
      { question },
      { headers }
    );
    const assistantResponse: string = response.data;
    if (assistantResponse) {
      return assistantResponse;
    }
    throw new Error('Ocurrio un problema');
  } catch (error) {
    console.error(error);
    throw new Error('Ocurrio un problema');
  }
}

export async function saveMessage(userToken: string, role: string, question: string): Promise<string> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    return await axiosInstance.post(
      '/chat-messages/save',
      { question, role },
      { headers }
    );
  } catch (error) {
    console.error(error);
    throw new Error('Ocurrio un problema');
  }
}

export async function getMessageHistory(userToken: string,
    skip: number,
    amount: number): Promise<MessagesResponse> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    const response = await axiosInstance.get(
      `/chat-messages?skip=${skip}&amount=${amount}`,
      { headers }
    );

    const { messages, allFetched } = response.data;
    return { foundMessages: messages, allFetched };

  } catch (error) {
    console.error(error);
    throw new Error('Ocurrio un problema al obtener el historial de mensajes');
  }
}

export async function changeDailyMenu(day: string, userToken: string) {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.post('/chat-messages/change-diet', { day }, { headers });
    return true;
  } catch (error) {
    console.error(error);
    throw new Error('No se pudo actualizar la dieta');
  }
}

export async function rateMessage(rate: string, messageId: string, userToken: string){
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.patch('/chat-messages/rate', { 
      rate,
      messageId
     }, { headers });
    return true;
  } catch (error) {
    console.error(error);
    throw new Error('No se pudo calificar el mensaje');
  }
}