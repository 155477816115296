import { Button } from 'react-bootstrap';
import { useIsIOSWebView } from '../../hooks/mobilePlatforms';

import './KitBanner.scss';

type BannerProps = {
  showBanner: boolean;
  onClick?: () => void;
}

function KitBanner({ showBanner, onClick }: BannerProps) {
  const isNativeIOS = useIsIOSWebView();

  return (showBanner ?
    <div className='kit-test-banner p-3' id='kit-banner'>
      {isNativeIOS ?
        <div className='d-flex flex-row gap-2 justify-content-between align-items-center'>
          <div> Adquiere un test de ADN y obtén una dieta con tu contexto genético </div>
          <div className="banner-ios"> Más en app.mynu.ai </div>
        </div>
        :
        <div className='d-flex flex-row gap-2 justify-content-between align-items-center'>
          <div>Adquiere un test de ADN y obtén una dieta con tu contexto genético</div>
          <Button
            variant="outline-primary"
            className="banner-button"
            onClick={onClick}
          >
            Comprar
          </Button>
        </div>

      }
    </div>
    : null)
}

export default KitBanner;
