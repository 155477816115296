import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import App from '../App';
import Signup from '../pages/signup/Signup';
import CodeConfirmation from '../pages/code-confirmation/CodeConfirmation';
import LoginPage from '../pages/login/Login';
import ProtectedRoute from './ProtectedRoute';
import PasswordRecovery from '../pages/password-recovery/PasswordRecovery';
import ChatPage from '../pages/chat/ChatPage';
import DietPage from '../pages/diets/DietPage';
import ResultPage from '../pages/results/ResultPage';
import PreLoginPage from '../pages/pre-login/PreLoginPage';
import Questionary from '../pages/questionary/QuestionaryPage';
import SettingsPage from '../pages/settings/SettingsPage';
import Subscription from '../pages/subscription/SubscriptionPage';
import BuyKitPage from '../pages/buy-kit/BuyKitPage';
import LandingPage from '../pages/landing/Landing';
import { NotFoundPage } from './404Page';
import { ResultsExplanation } from '../pages/results-explanation/ResultsExplanation';
import AdminPage from '../pages/admin/AdminPage';
import FesReportPage from '../pages/diets/components/Fes/FesReportPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <App />
          </ProtectedRoute>
        }
      />
      <Route path="/signup" element={<Signup />} />
      <Route path="/code-confirmation" element={<CodeConfirmation />} />
      <Route path="/buy-kit/" element={<BuyKitPage />} />
      <Route path="/subscription/" element={<Subscription />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/qr" element={<PreLoginPage />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route
        path="/diets"
        element={
          <ProtectedRoute>
            <DietPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/diets/fes/report/daily"
        element={
          <ProtectedRoute>
            <FesReportPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chat"
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/results-explanation/:category/:subcategory/:predisposition/:advice/:scroll?'
        element={
          <ProtectedRoute>
            <ResultsExplanation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/results"
        element={
          <ProtectedRoute>
            <ResultPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/questionary"
        element={
          <ProtectedRoute>
            <Questionary />
          </ProtectedRoute>}
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>}
      />
      <Route
        path="/subscription/"
        element={
          <ProtectedRoute>
            <Subscription />
          </ProtectedRoute>}
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <AdminPage />
          </ProtectedRoute>
        }
        /> 
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </>
  )
);

export default router;
