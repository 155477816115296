import '../../styles/App.scss';
import './SubscriptionBadge.scss';
import { Badge } from "react-bootstrap";
import { Subscription } from '../../models';
import { getSubscriptionType } from '../../constants'

type SubscriptionBadgeProps = {
  subscription?: Subscription | null;
}

export default function SubscriptionBadge({ subscription }: SubscriptionBadgeProps) {

  const validSubscription = (subscription: Subscription) => {
    if (subscription.active === false) return false;
    if (subscription.end_date) {
      const now = new Date();
      const expirationDate = new Date(subscription.end_date);
      if (now > expirationDate) return false;
    }
    return true;
  }

  return (
    <div className='badge-container'>
      <h1>
        {
          subscription?.type === 'premium' && validSubscription(subscription) ?
            <Badge pill className='my-0'>
              {getSubscriptionType(subscription?.type ?? '')}
            </Badge>
            :
            <Badge pill className='my-0' style={{ color: '#33B491'}}>
              Freemium
            </Badge>
        }
      </h1>
    </div>
  )
}
