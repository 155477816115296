import { DAY, LOWERCASE_DAY, MEAL } from "../utils/constants";

export type FesMeal = {
  recipeId: string,
  recipeName: string,
  foodGroup1: string | null,
  foodSubgroup1?: string | null,
  foodGroup2?: string | null,
  foodSubgroup2?: string | null,
  foodGroup3?: string | null,
  foodSubgroup3?: string | null,
  score: number,
  amountGrams?: number,
  amountUnits?: number,
  unit?: string,
  kiloCalories?: number,
  mealTypes: MEAL[],
  tags: string[]
}

export type RecommendedFoodsType = {
  mealType: MEAL;
  recommendedGroups: string[];
}

export type fesPortionType = {
  mealType: MEAL;
  foodList: FesMeal[];
}

export type fesNutrientReportType = {
  name: string,
  value: number,
  unit: string,
  dailyLb: number,
  dailyUb: number,
}


export type fesRecipeScoresReport = {
  average: number
}

export type fesFoodVarietyReportType = {
  label: string;
  count: number;
}

export type fesMacrosType = {
  protein: number;
  carbs: number;
  fat: number;
}

export type fesReportType = {
  nutrients: fesNutrientReportType[];
  recipeScoresSummary: fesRecipeScoresReport;
  numberOfDifferentFoods: number;
  differentMealTypes: number;
  foodVariety: fesFoodVarietyReportType[];
  overallScore: number;
  improvementSections: string[]
}

export type portionsType = {
  [key in MEAL]: {
    portions: FesMeal[] | null;
    skipped?: boolean;
  };
}

export type dailyPortionsType = {
  [key in LOWERCASE_DAY]: portionsType;
}

export type weeklyViewType = {
  ix: number,
  s: string,
  ld: LOWERCASE_DAY,
  cd: DAY
}

export enum FoodGroups {
  DAIRY = 'Lácteos y bebidas vegetales',
  SIDE_DISH = 'Acompañamiento',
  BREAD = 'Panes y cereales',
  PROTEIN = 'Proteínas',
  DESSERT = 'Postre',
  ADD = 'Agregado',
  VEGETABLES = 'Verduras',
  COMPOSITE_DISH = 'Plato compuesto',
  FATS = 'Aceite y Aderezos',
  FRUIT = 'Fruta',
  NUTS = 'Frutos secos y Semillas',
  LIQUID = 'Líquidos',
  SOUPS = 'Sopas y cremas',
  SAUCES = 'Salsas',
  FREE = 'Consumo libre'
}

export const breakfastSnacks = [
  FoodGroups.DAIRY,
  FoodGroups.ADD,
  FoodGroups.FRUIT,
  FoodGroups.BREAD,
  FoodGroups.NUTS,
  FoodGroups.PROTEIN,
  FoodGroups.LIQUID,
  FoodGroups.FREE,
]

export const lunchDinner = [
  FoodGroups.SIDE_DISH,
  FoodGroups.COMPOSITE_DISH,
  FoodGroups.VEGETABLES,
  FoodGroups.FATS,
  FoodGroups.DESSERT,
  FoodGroups.SOUPS,
  FoodGroups.PROTEIN,
  FoodGroups.LIQUID,
  FoodGroups.FREE,
]

export const foodGroupHierarchy = [
  FoodGroups.PROTEIN,
  FoodGroups.LIQUID,
  FoodGroups.SIDE_DISH,
  FoodGroups.COMPOSITE_DISH,
  FoodGroups.SAUCES,
  FoodGroups.VEGETABLES,
  FoodGroups.FATS,
  FoodGroups.SOUPS,
  FoodGroups.DESSERT,
  FoodGroups.FRUIT,
  FoodGroups.DAIRY,
  FoodGroups.BREAD,
  FoodGroups.NUTS,
  FoodGroups.ADD,
  FoodGroups.FREE,
]