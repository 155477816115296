import { axiosInstance } from "../../api/http";
import { Meal } from "../../models/diet-model";
import { FesMeal, fesReportType, RecommendedFoodsType } from "../../models/fes-model";
import { LOWERCASE_DAY, lowerDays, MEAL } from "../../utils/constants";

type DietStatus = "GENERATED" | "FAILED" | "LOADING";

type DietResponsePayload = {
  meals: Meal[],
  status: DietStatus
}

type Diet = {
  meals: Meal[][],
  status: DietStatus
}

export async function getDiet(days: string[], userToken: string): Promise<Diet> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    const response = await axiosInstance.get('/diets', { headers });
    const dietResponsePayload: DietResponsePayload = response.data;

    const { meals, status } = dietResponsePayload;

    if (status !== "GENERATED") {
      return { meals: [], status };
    }

    const dietByDay = days.map((day) => {
      return meals.filter((meal) => {
        return (meal.day).toLowerCase() === (day).toLowerCase();
      });
    });
    return { meals: dietByDay, status };
  } catch (error) {
    console.error(error);
    throw new Error('DietService - getDiet: No se pudo obtener la dieta');
  }
}

export async function changeDailyMenu(day: string, userToken: string) {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.post('/diets/change-diet', { day }, { headers });
    return true;
  } catch (error) {
    console.error(error);
    throw new Error('DietService - changeDailyMenu: No se pudo actualizar la dieta');
  }
}

export async function changeMenuByIngredient(params: {
  day: string;
  menuName: string;
  ingredient: string;
  ingredientHistory: string;
  userToken: string;
  reason: string;
}) {
  const { day, menuName, ingredient, userToken, ingredientHistory, reason } = params;
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.post('/diets/change-ingredient', {
      day,
      menuName,
      ingredient,
      ingredientHistory,
      reason
    }, { headers });
    return true;
  } catch (error) {
    console.error(error);
    throw new Error('DietService - changeMenuByIngredient: No se pudo actualizar la dieta');
  }
}

export async function getMealOptions(day: string, menuName: string, userToken: string) {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    const response = await axiosInstance.post('/diets/menu-options', {
      day,
      menuName
    }, { headers });
    const mealOptions: Record<number, string[]> = response.data;
    if (mealOptions) {
      return Object.values(mealOptions);
    }
    throw new Error('DietService - getMealOptions: No se pudo obtener opciones de menú');
  } catch (error) {
    console.error(error);
    throw new Error('DietService - getMealOptions: No se pudo obtener opciones de menú');
  }
}

export async function changeMeal(day: string, menuName: string, newMenu: string[], userToken: string) {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.post('/diets/change-menu', {
      day,
      menuName,
      newMenu
    }, { headers });
    return true
  } catch (error) {
    console.error(error);
    throw new Error('DietService - changeMeal: No se pudo actualizar el menú');
  }
}


export async function requestNewDiet(userToken: string): Promise<number> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }

    const { status } = await axiosInstance.get('/diets/request-new-diet', { headers });
    return status;
  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message

    throw new Error(message);
  }
}

export async function fesGetRecommendedGroups(day: LOWERCASE_DAY): Promise<RecommendedFoodsType[]> {
  const userToken = localStorage.getItem('userToken');
  try {

    if (!(lowerDays.includes(day))) throw new Error('day not found');

    const headers = { "Authorization": `Bearer ${userToken}` }
    const response = await axiosInstance.post(`/fes/recommended-groups?weekDay=${day}`, {}, { headers });
    return response.data;

  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message
    throw new Error(message);
  }
}

export async function fesGetFoodList(): Promise<FesMeal[]> {
  const userToken = localStorage.getItem('userToken');
  try {
    const headers = { "Authorization": `Bearer ${userToken}` }

    const respose = await axiosInstance.get('/fes/recipes', { headers });
    return respose.data;

  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message
    throw new Error(message);
  }
}

export async function fesCalculatePortions(date: string, mealType: MEAL, recipeIds: string[],): Promise<FesMeal[]> {
  const userToken = localStorage.getItem('userToken');
  try {
    const headers = { "Authorization": `Bearer ${userToken}` };
    const data = {
      date,
      mealType,
      recipeIds
    }
    const response = await axiosInstance.post('/fes/portions', { ...data }, { headers });
    return response.data;

  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message
    throw new Error(message);
  }
}

export async function fesGetPortions(from: string, to: string): Promise<{date: string, mealType: MEAL, portions: FesMeal[] }[]> {
  const userToken = localStorage.getItem('userToken');
  try {
    const headers = { 'Authorization': `Bearer ${userToken}` };
    const response = await axiosInstance.get(`/fes/portions?from=${from}&to=${to}`, { headers });
    return response.data;
  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message
    throw new Error(message);
  }
}

export async function fesGetDailyReport(weeekDay: string): Promise<fesReportType> {
  const userToken = localStorage.getItem('userToken');
  try {
    const headers = { 'Authorization': `Bearer ${userToken}` };
    const response = await axiosInstance.get(`/fes/summary?date=${weeekDay}`, { headers });
    return response.data;
  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message
    throw new Error(message);
  }
}