import React, { useState, useEffect, useMemo, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Popover from 'react-bootstrap/Popover';

import { RenderRecipeScore } from './FesCardComponent';

import { DashCircleFill, SortAlphaDown, SortAlphaUpAlt, X, XCircleFill } from 'react-bootstrap-icons';
import { FesMeal } from '../../../../models/fes-model';
import { normalizeString } from '../../../../utils';
import { Overlay } from 'react-bootstrap';
import { MYNU_PRIMARY_COLOR } from '../../../../utils/constants';

const { Control } = Form;

type SearchComponentProps = {
  group: string;
  options: FesMeal[],
  rootSelectedIds: { [group: string]: string[] },
  handleRootSelection: (group: string, clickedItemId: string) => void;
  changeRecommendedGroups: (group: string, add: boolean) => void;
  extra?: boolean
}

export default function FesSearchComponent(props: SearchComponentProps) {
  const {
    group,
    options,
    rootSelectedIds,
    handleRootSelection,
    changeRecommendedGroups,
    extra
  } = props;

  const selectedIds = useMemo(() => rootSelectedIds[group] ?? [], [rootSelectedIds, group]);

  const [renderOptions, setRenderOptions] = useState<FesMeal[] | null>(null);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (options && options.length > 0) {
      setRenderOptions(options);
    }
  }, [options]);

  const filterOptions = (search: string) => {
    const normalizedSearch = normalizeString(search);

    const filtered = options.filter(({ recipeName, tags }: FesMeal) => {
      const normalizedRecipeName = normalizeString(recipeName);
      const parsedTags = tags.map(s => s.replaceAll('_', ' '))
      return normalizedRecipeName.includes(normalizedSearch) || parsedTags.some(t => t.includes(normalizedSearch));
    });

    if (search.length === 0) {
      setRenderOptions(options);
    } else {
      setRenderOptions(filtered);
    }
  }

  const handleTextChange = (searchString: string) => {
    setSearchText(searchString);
    setTimeout(() => {
      filterOptions(searchString);
    }, 200);
  }

  const clearInput = () => {
    setSearchText('');
    filterOptions('');
  }

  // overlay setup
  const [showOverlay, setShowOverlay] = useState(false);
  const inputSearchRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  // Close the popover if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (inputSearchRef.current && !inputSearchRef.current.contains(event.target as Node) &&
      popoverRef.current && !popoverRef.current.contains(event.target as Node)
    ) {
      setShowOverlay(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${extra ? 'extra' : undefined} pb-2 fes-search-component mynu-shadow`}>
      <div className={`fes-search-component-header`}>
        <div className='item1'>
          {group}
        </div>
        <div className='d-flex flex-column align-items-end item2'>
          <DashCircleFill
            size={21}
            className="ms-auto mynu-stars point"
            onClick={() => changeRecommendedGroups(group, false)}
          />
        </div>

        <div className={`item3`} ref={inputSearchRef}>
          <InputGroup >
            <Control
              id={`fes-input-search-${group}`}
              placeholder={'🔎 Escoge un alimento'}
              type="text"
              onFocus={() => setShowOverlay(true)}
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e.target.value)}
            />
            {searchText && (
              <Button variant="outline-primary" onClick={clearInput} size='sm'
                style={{
                  backgroundColor: '#fff',
                  border: 'none',
                  padding: '0px',
                  margin: '0px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <InputGroup.Text
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} >
                  <X size={25} />
                </InputGroup.Text>
              </Button>
            )}

          </InputGroup>

        </div>
        <Overlay target={inputSearchRef.current} show={showOverlay} placement='bottom-start' >
          {(props) =>
            <Popover {...props} id='search-results' className='popover-container'>
              <div ref={popoverRef} className='m-1 popover-content'>
                <RenderResults
                  renderOptions={renderOptions}
                  handleSelect={id => handleRootSelection(group, id)}
                  selectedIds={selectedIds}
                  options={options}
                />
              </div>
            </Popover>
          }
        </Overlay>
      </div>
      {
        selectedIds.length > 0 ?
          <RenderFoodRecommendation
            meals={options}
            selectedIds={selectedIds}
            group={group}
            handleClick={(id) => handleRootSelection(group, id)}
          />
          : null
      }
    </div>
  )
}

const RenderResults = (
  { renderOptions, handleSelect, selectedIds, options }:
    {
      renderOptions: FesMeal[] | null,
      handleSelect: (id: string) => void,
      selectedIds: string[],
      options: FesMeal[]
    }
) => {

  const [sortValue, setSortValue] = useState<boolean | null>(null);

  const optionsState = useMemo(() => {
    let sorted = renderOptions ?? [];
    if (sorted) {
      if (sortValue === null) {
        return sorted?.sort((a, b) => b.score - a.score);
      };

      if (sortValue) {
        sorted = sorted?.sort((a, b) => a.recipeName < b.recipeName ? 1 : -1);
      } else {
        sorted = sorted?.sort((a, b) => a.recipeName > b.recipeName ? 1 : -1);
      }
    }
    return sorted;
  }, [renderOptions, sortValue]);

  return (
    <div className='p-2'>
      {optionsState && optionsState.length > 0 ?
        <div className='p-1 m-1 search-filters' >
          <div className='ms-auto'>
            {!sortValue ?
              <Button variant='outline-primary' className='mx-1' onClick={() => setSortValue(true)} >
                <SortAlphaDown size={20} />
              </Button>
              :
              <Button variant='outline-primary' className='mx-1' onClick={() => setSortValue(false)} >
                <SortAlphaUpAlt size={20} />
              </Button>
            }
            {sortValue !== null ?
              <XCircleFill size={20} onClick={() => setSortValue(null)} color={MYNU_PRIMARY_COLOR} />
              : null
            }
          </div>
        </div>
        : null
      }
      {optionsState && optionsState.length > 0 ?
        optionsState.map(({ recipeId, recipeName, score }: FesMeal, ix: number) => (
          <div key={ix}
            className='d-flex flex-row align-items-center mynu-outline point p-1 m-1'
            onClick={() => handleSelect(recipeId)} >
            <div className='m-1 px-2 d-flex'>
              <Form.Check
                required={false}
                name={recipeName}
                type={'checkbox'}
                id={recipeId}
                onChange={() => { }}
                {
                ...{ checked: selectedIds.includes(recipeId), }
                }
              />
            </div>
            <div className='m-1 d-flex flex-grow-1'> {recipeName} </div>

            <RenderRecipeScore score={score} size={'15px'} />
          </div>
        ))
        :
        <div className='text-center' style={{ margin: '2rem' }}>
          <span className='mb-3'><h1 className='mynu-stars'>{":'("}</h1></span>
          <div className='mt-3'>No se encontraron resultados similares </div>
        </div>
      }
    </div>
  )
}


export const RenderFoodRecommendation = (
  { selectedIds, meals, group, handleClick }:
    {
      meals: FesMeal[],
      selectedIds: string[],
      group: string,
      handleClick: (id: string) => void
    }
) => {
  return (
    <div className="fes-search-component-body" >
      {selectedIds.map((id, index) => {
        let meal = meals.find(meal => meal.recipeId === id);
        let foodName = meal?.recipeName;
        let foodGroup1 = meal?.foodGroup1;
        let foodGroup2 = meal?.foodGroup2;
        let foodGroup3 = meal?.foodGroup3;
        if (foodName && foodName.length > 0
          && ([foodGroup1, foodGroup2, foodGroup3].includes(group) || group === 'Todo' || !group)
        ) {
          return (
            meal ?
              <div className="fes-portions-container selected-item mynu-outline" key={`${foodName}-${group}-${index}`} onClick={() => handleClick(id)} >
                <div className="text-capitalize recipe-name">
                  {foodName}
                </div>

                <RenderRecipeScore score={meal.score} size={'15px'} />

                <div className="portion-amount">
                  <XCircleFill className="mynu-stars point" size={20} />
                </div>
              </div>
              : null
          )
        }
        return null;
      })}
    </div>
  )
}