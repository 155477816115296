import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Container, Stack } from 'react-bootstrap';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

// state & context
import { useCurrentUser } from "../../components/AuthContext";
import { useStateStore } from '../../store/store';
import { loadDiet, loadUserInfo, setMealRestrictions } from '../../store/loader';

// services & utils
import { isEmptyOrUndefined, validSubscription } from '../../utils';

// my componetns
import MainLayout from '../../components/MainLayout/MainLayout';
import Notification from '../../components/Notification/Notification';
import SubscriptionBanner from '../../components/SubscriptionBanner/SubscriptionBanner'
import Spinner from '../../components/Spinner/Spinner';

import ProfileSummary from './components/ProfileSummary';
import DietAccordion from './components/DietAccordion/DietAccordion';

// assets
import './DietPage.scss';
import { LoadingPage } from '../../router/LoadingPage';
import { days } from '../../utils/constants';
import DietPortionsComponent from './components/DietPortionsComponent';
import DietSummaryComponent from './components/SummaryItem/DietSummaryComponent';
import FesComponent from './components/Fes/FesComponent';
import FesTutorial from '../../components/Tutorials/FesTutorial';
import { QuestionCircleFill } from 'react-bootstrap-icons';

function DietPage() {

  const currentUser = useCurrentUser();
  const store = useStateStore();

  const {
    dietInformation,
    isLoadingDiet,
    dietCreationFailed,
    questionnaireOptions,
    questionnaireData,
    dietMealRestrictions,
    resultStatus,
    resultMealsRestrictions,
  } = store;

  const [notificationText, setNotificationText] = useState('');
  const notificationError = useMemo(() => notificationText.toLowerCase().includes('error'), [notificationText]);
  const notificationOpen = useMemo(() => notificationText.length > 0, [notificationText]);

  const [isSubscriptionBanner, setSubscriptionBanner] = useState('');
  const [openAccordionDay, setOpenDietAccordionDay] = useState('dailyMeal-Lunes');

  useEffect(() => {
    if (currentUser && currentUser?.subscription) {
      const subscription = currentUser?.subscription;
      if (validSubscription(subscription)) {
        setSubscriptionBanner('none');
      }
      if (subscription.end_date && subscription.end_date < new Date().toISOString()) {
        setSubscriptionBanner('expiredAlert');
      }
      if (subscription.type === 'freemium') {
        setSubscriptionBanner('freemium');
      }

      loadUserInfo(store, currentUser);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDietInfo = async () => {
    try {
      const userToken = localStorage.getItem('userToken');
      await loadDiet(store, currentUser, userToken);
    } catch (error: any) {
      setNotificationText('Ha ocurrido un error cargando tu dieta');
    }
  }

  const checkDiet = async () => {
    if (isLoadingDiet) {
      await loadDietInfo();
    } else {
      return
    }
  };

  useEffect(() => {
    if (store.hasDiet()
      && store.hasResults()
      && store.hasQuestionnaireData()
      && !isEmptyOrUndefined(questionnaireOptions, 'obj')
      && !isEmptyOrUndefined(store, 'obj')
    ) {
      setMealRestrictions(store)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dietCreationFailed, isLoadingDiet, questionnaireData, questionnaireOptions])

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (currentUser?.resultsDate && userToken && isLoadingDiet) {
      checkDiet()
      const intervalId = setInterval(checkDiet, 10000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoadingDiet]);


  const [isFesTutorialOpen, setIsFesTutorialOpen] = useState(false);

  const myPautaTooltip = (
    <div className="diet-tab-title gap-md-1 d-flex flex-column flex-column justify-content-center align-items-center">
      My Pauta

      <Button
        className="details-button"
        onClick={() => setIsFesTutorialOpen(true)}
      >
        <Stack direction='horizontal' gap={2}>
          <div>
            Nuevo
          </div>
          <QuestionCircleFill size={'1rem'} />
        </Stack>
      </Button>
    </div>
  )
  return (
    <MainLayout>
      {currentUser?.resultsDate &&
        questionnaireData && Object.keys(questionnaireData).length > 0 ?
        <>
          <FesTutorial show={isFesTutorialOpen} handleClose={() => setIsFesTutorialOpen(!isFesTutorialOpen)} />
          <Notification
            show={notificationOpen}
            text={[notificationText]}
            variant={notificationError ? 'danger' : 'success'}
            onClose={() => setNotificationText('')}
          />
          <div className="page pt-2 pt-lg-4 px-0">
            <Container className="page-container">
              <Card className="p-4 mb-4 diet-header">
                <h1 className="diet-title">Planificación de mi dieta</h1>
                <p className="text mt-3">¡Bienvenido a tu primera dieta personalizada! Hemos creado un plan semanal adaptado a tus necesidades nutricionales, objetivos y preferencias.</p>
                <hr />
                {questionnaireData !== null && questionnaireOptions != null &&
                  <ProfileSummary
                    questionnaireData={questionnaireData}
                    questionnaireOptions={questionnaireOptions}
                  />
                }
              </Card>
              <SubscriptionBanner type={isSubscriptionBanner} onClick={() => { }} />

              {!isLoadingDiet && !dietCreationFailed &&
                <Card className="p-0 mb-4 diet-header">
                  <Tabs
                    defaultActiveKey="diet"
                    id="diet-page-tabs"
                    className="d-flex align-items-end p-2"
                    fill
                    justify
                  >
                    <Tab eventKey="diet"
                      title={<div className='diet-tab-title'>My Menú</div>}
                      className='diet-tab-item'
                    >
                      <DietAccordion
                        days={days}
                        diet={dietInformation}
                        loadDiet={loadDietInfo}
                        currentUser={currentUser}
                        openAccordionDay={openAccordionDay}
                        setOpenDietAccordionDay={setOpenDietAccordionDay}
                        setNotificationText={setNotificationText}
                      />
                    </Tab>
                    {!isEmptyOrUndefined(resultStatus, 'str')
                      && !isEmptyOrUndefined(resultMealsRestrictions.allowed, 'arr')
                      && !isEmptyOrUndefined(dietMealRestrictions, 'obj') &&
                      <Tab eventKey="summary" title={<div className='diet-tab-title'>Guía Nutricional</div>} className='diet-tab-item'>
                        <DietSummaryComponent dietMealRestrictions={dietMealRestrictions} />
                      </Tab>
                    }
                    <Tab eventKey="portions"
                      title={<div className='diet-tab-title'>Tamaño de Porciones</div>}
                      className='diet-tab-item'
                    >
                      <DietPortionsComponent />
                    </Tab>

                    <Tab eventKey="fes"
                      title={myPautaTooltip}
                      className='diet-tab-item'
                    >
                      <FesComponent />
                    </Tab>
                  </Tabs>
                </Card>
              }
              {isLoadingDiet && <div className="loader-container">
                <Spinner size='medium' rotate={true} />
                <span className="loader-text mt-3 text-center">¡Estamos generando tu dieta!</span>
                <br />
                <p >Te notificaremos por email cuando esté lista</p>
              </div>}
              {dietCreationFailed &&
                <div className="loader-container">
                  <div className='mb-4'>
                    <Spinner size='medium' rotate />
                  </div>
                  <span className="loader-text mt-3 text-center">Cargando tu dieta</span>
                  <br />
                  <p className='explanation-paragraph'>
                    Estamos tardando más de lo habitual generando tu dieta. Porfavor, espera a que tu dieta esté lista.
                  </p>
                </div>
              }
              <Card className="px-3 py-5 px-lg-4 mb-4 diet-header">
                <h2 className="summary-title mb-4 font-avenir-bold">🥗 Resumen de la semana:</h2>
                <span className="font-avenir-bold">✅ Adaptado a ti:</span>
                <p className="text my-1 my-lg-3">Tomando en cuenta tu genética, tus hábitos, gustos y objetivos, hemos diseñado un plan que te llevará a la versión más saludable de ti mismo.</p>
                <span className="font-avenir-bold">✅ Variedad y Sabor:</span>
                <p>Olvídate de las dietas monótonas. Cada día es una nueva oportunidad para explorar sabores y recetas que nutren tu cuerpo.</p>
              </Card>
            </Container>
          </div>
        </>
        : <LoadingPage />
      }
    </MainLayout>
  )
}

export default DietPage;
