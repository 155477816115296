import { ReactNode } from 'react';
import AppNavbar from './AppNavbar';
import HealthDisclaimerComponent from '../HealthDisclaimer/HealthDiscalimer';


type MainLayoutProps = {
  children: ReactNode,
}

function MainLayout({ children }: MainLayoutProps) {
  return (
    <>
      <AppNavbar />
      <div style={{ margin: "3rem 0 0 0" }}>
        {children}
      </div>
      <HealthDisclaimerComponent />
    </>
  );
}

export default MainLayout;
