import { useState } from "react";
import { Collapse, Container } from "react-bootstrap";
import { InfoCircle } from 'react-bootstrap-icons';

export default function HealthDisclaimerComponent() {


  const [disclaimer, setDisclaimer] = useState(false);

  return (
    <div
      className="pb-3 d-flex flex-column justify-content-center align-items-center"
    >
      <i>
        <Container>
          <div className="" style={{ margin: '0 1rem', color: 'gray', textAlign: 'justify' }}>
            <div className="px-2">
              <InfoCircle width={40} className='m-2 mynu-purple' />
              En Mynu trabajamos con nutricionistas, genetistas, bioquímicos y bioinformáticos. Con ellos, creamos un panel genético a partir de estudios GWAS
              (genome-wide association study) con criterios estrictos para asegurar la calidad y precisión de nuestros resultados. {' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={(e) => { e.preventDefault(); setDisclaimer(!disclaimer) }}>{disclaimer ? '(Menos)' : '(Más)'}</a>
            </div>
            <Collapse in={disclaimer}>
              <div>
                <ul style={{ margin: '1rem' }}>
                  <li>
                    Los estudios seleccionados son genéticamente relevantes y con valores estadísticos y de riesgo descritos en poblaciones de interés. <br />
                  </li>
                  <li>
                    En estos, se consideran estudios poblacionales, que involucran aspectos genéticos y fenotípicos en cohortes amplias (tras-etnia, género, edad, clases minorías, etc.). <br />
                  </li>
                  <li>
                    Para más información puedes visitar nuestra evidencia científica en <a href="https://mynu.ai/ciencia-conciencia" target="_blank" rel='noreferrer'>mynu.ai/ciencia-conciencia</a>.
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
        </Container>
      </i>
    </div>
  )
}
